const getMobileDetect = (userAgent) => {
  const isAndroid = () => Boolean(userAgent.match(/Android/i))
  const isIos = () => Boolean(userAgent.match(/iPhone|iPad|iPod/i))
  const isOpera = () => Boolean(userAgent.match(/Opera Mini/i))
  const isWindows = () => Boolean(userAgent.match(/IEMobile/i))
  const isSSR = () => Boolean(userAgent.match(/SSR/i))
  const isMobile = () =>
    Boolean(isAndroid() || isIos() || isOpera() || isWindows())
  const isDesktop = () => Boolean(!isMobile() && !isSSR())
  return {
    isMobile,
    isDesktop,
    isAndroid,
    isIos,
    isSSR
  }
}

const useMobileDetect = () => {
  const userAgent =
    typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent
  return getMobileDetect(userAgent)
  // if (typeof window === 'undefined') return false
  // const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
  // function mq(query) {
  //   return typeof window !== 'undefined' && window.matchMedia(query).matches
  // }
  // // @ts-ignore
  // if (
  //   'ontouchstart' in window ||
  //   (window?.DocumentTouch && document instanceof DocumentTouch)
  // )
  //   return true
  // const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('') // include the 'heartz' - https://git.io/vznFH
  // return mq(query)
}

export default useMobileDetect
