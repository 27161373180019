import { createSlice } from '@reduxjs/toolkit'
// import { AppState } from "./store";
import { HYDRATE } from 'next-redux-wrapper'

// Initial state
const initialState = {
  authState: false,
  signShow: false,
  userInfo: {}
}

// Actual Slice
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // Action to set the authentication status
    setAuthState(state, action) {
      state.authState = action.payload
    },

    setUserInfo(state, action) {
      state.userInfo = action.payload
    },

    setSignShow(state, action) {
      state.signShow = action.payload
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.auth
        }
      }
    }
  }
})

export const { setAuthState, setUserInfo, setSignShow } = authSlice.actions

export const selectAuthState = (state) => state.auth.authState

export default authSlice.reducer
