import '../styles/globals.scss'
// import '../styles/globals.css'
import 'antd/dist/antd.min.css'
import { useEffect } from 'react'
import Script from 'next/script'
import { Provider } from 'react-redux'
// import { Loader } from '@googlemaps/js-api-loader'
import Layout from '../layout'
import { wrapper } from '../store'

const apiKey = 'AIzaSyBnW3-ZW0MoCXZ87SHhwnEFJF7LD1MsTnY'

function MyApp({ Component, ...rest }) {
  const { store, props } = wrapper.useWrappedStore(rest)

  useEffect(() => {
    if (document && window) {
      const mapsLoaded = new Event('googleMapsLoaded')
      window.initGoogleMap = () => {
        window.dispatchEvent(mapsLoaded)
      }
      // setTimeout(() => {
      //   const loader = new Loader({
      //     apiKey: 'AIzaSyB49sWW0o7AZAVb81HCXvhzjYun0qeF-Zo',
      //     libraries: ['places'],
      //     version: 'weekly'
      //   })
      //   let time = 0
      //   let timer = setInterval(() => {
      //     console.log(loader)
      //     if (window.google && loader && loader.done && loader.importLibrary) {
      //       loader.importLibrary('places').then((r) => {
      //         window.google.maps.places = r
      //         time = 0
      //         clearInterval(timer)
      //       })
      //     } else if (time === 10) {
      //       clearInterval(timer)
      //     } else {
      //       time += 1
      //     }
      //   }, 200)
      // }, 1000)
    }
  }, [])

  return (
    <Provider store={store}>
      <Layout>
        <Script
          src={`https://maps.googleapis.com/maps/api/js?key=${apiKey}&v=beta&libraries=places,marker&sensor=false&callback=initGoogleMap`}
        ></Script>
        <Script
          src="https://www.googletagmanager.com/gtag/js?id=G-6MDTVCQH1Y"
          strategy="afterInteractive"
        />
        <Script id="google-analytics" strategy="afterInteractive">
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-6MDTVCQH1Y');
        `}
        </Script>
        <Script
          src="https://unpkg.com/vconsole@latest/dist/vconsole.min.js"
          onLoad={() => {
            if (location.host != 'ymontessori.com') {
              const vConsole = new window.VConsole()
            }
          }}
        />
        <Component {...props.pageProps} />
      </Layout>
    </Provider>
  )
}

export default MyApp
