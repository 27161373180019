import axios from 'axios'
import { message } from 'antd'
import { wrapper } from '../store'

const isDev = process.env.NODE_ENV === 'development'
const baseURL = isDev ? '' : process.env.NEXT_PUBLIC_API

const instance = axios.create({
  baseURL,
  timeout: 10000
})

instance.interceptors.request.use(
  (config) => {
    // 在此处添加您想要执行的操作，例如设置headers、添加token等。
    const opt = {
      ...config,
      headers: {
        ...(config.headers || {}),
        token: localStorage.getItem('TOKEN')
      }
    }
    return opt
  },
  (error) => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  (response) => {
    // 在此处添加针对响应的处理逻辑，例如统一处理错误、过滤数据等。
    // 记得封装统一错误提示
    if (response.data && response.data.code === 0) {
      return response.data
    } else {
      if (response.data.code !== 10005) {
        message.error({ content: response.data.msg })
      }
      return Promise.reject(response.data)
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default instance
