import CryptoJS from 'crypto-js'
import http from './request'
import { initializeApp, getApps, getApp } from 'firebase/app'
import { initializeAuth, getAuth } from 'firebase/auth'

export const request = http

export function isEmail(str) {
  var reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
  return reg.test(str)
}

export function GetDistance(lat1, lng1, lat2, lng2) {
  var radLat1 = (lat1 * Math.PI) / 180.0
  var radLat2 = (lat2 * Math.PI) / 180.0
  var a = radLat1 - radLat2
  var b = (lng1 * Math.PI) / 180.0 - (lng2 * Math.PI) / 180.0
  var s =
    2 *
    Math.asin(
      Math.sqrt(
        Math.pow(Math.sin(a / 2), 2) +
          Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)
      )
    )
  s = s * 6378.138 // EARTH_RADIUS;
  s = Math.round(s * 10000) / 10000
  return (s / 1.609343998599119).toFixed(2)
}

// 加密函数
export function encrypt(data, key) {
  const iv = CryptoJS.lib.WordArray.random(16) // 生成随机初始向量
  const encryptedData = CryptoJS.AES.encrypt(data, key, { iv })
  return { iv: iv.toString(), encryptedData: encryptedData.toString() }
}

// 解密函数
export function decrypt(encryptedData, key) {
  const iv = CryptoJS.lib.WordArray.random(16)
  const decryptedData = CryptoJS.AES.decrypt(encryptedData, key, {
    iv: CryptoJS.enc.Hex.parse(iv)
  })
  return decryptedData.toString(CryptoJS.enc.Utf8)
}

// 判断是否为线上测试环境
export const isOnlineDev =
  process.env.NEXT_PUBLIC_API &&
  process.env.NEXT_PUBLIC_API.indexOf('.dev.') > -1

export const mobileKeywords = [
  'iphone',
  'android',
  'windows phone',
  'blackberry',
  'mobile',
  'opera mini',
  'iemobile',
  'mobile safari'
]
export function isIncognitoMode() {
  return new Promise((resolve) => {
    try {
      localStorage.setItem('test', 'test')
      localStorage.removeItem('test')
      resolve(false)
    } catch (e) {
      resolve(true)
    }
  })
}

const firebaseConfig = {
  //prod
  apiKey: 'AIzaSyCJgYi9mEzsOJpSxNwMlaOLQf3f4--YhU0',
  authDomain: 'y-montessori.firebaseapp.com',
  projectId: 'y-montessori',
  storageBucket: 'y-montessori.appspot.com',
  messagingSenderId: '116077103998',
  appId: '1:116077103998:web:15f83b884c581d5cbfc12f',
  measurementId: 'G-K6RWT5R3YM'
  // dev
  // apiKey: 'AIzaSyB49sWW0o7AZAVb81HCXvhzjYun0qeF-Zo',
  // authDomain: 'dev-ymontessori.firebaseapp.com',
  // projectId: 'dev-ymontessori',
  // storageBucket: 'dev-ymontessori.appspot.com',
  // messagingSenderId: '649411208187',
  // appId: '1:649411208187:web:859987fca9a029ac45615e',
  // measurementId: 'G-R2TL9GV5BC'
}
// Initialize Firebase
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp()
const _auth = !getApps().length ? initializeAuth(app) : getAuth(app)

export const _firebaseAuth = _auth
