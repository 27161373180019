import styles from './footer.module.scss'
import Link from 'next/link'
import Image from 'next/image'
import Router from 'next/router'

export default function Footer({ children }) {
  const { pathname } = Router.useRouter()
  const setMaxWidth = () => {
    switch (pathname) {
      case '/':
        return 1318
      case '/list':
        return 'calc(100% - 40px)'
      case '/detail':
        return 1080
      default:
        return 'calc(100% - 40px)'
    }
  }
  return (
    <footer className={styles.footer}>
      {/* <div className={styles.logo}>
        <Image
          src="/logo-small.png"
          alt="Y Montessori"
          width={200}
          height={150}
        ></Image>
      </div> */}
      <div className={styles.box} style={{ maxWidth: setMaxWidth() }}>
        <div className={styles.left}>
          <img src="/logo.jpg" alt="ymontessori"></img>
        </div>
        <div className={styles.right}>
          <p className={styles.rightCity}>San Diego, California　|　</p>
          <p className={styles.rightCity2}>San Diego, California</p>
          <Link
            href="/cookie"
            className={styles.rightItem}
            style={{ padding: 0 }}
          >
            Cookie Policy
          </Link>
          <Link href="/terms" className={styles.rightItem}>
            Terms and Conditions
          </Link>
          <Link href="/privacy" className={styles.rightItem}>
            Privacy Policy
          </Link>
        </div>
        {/* <div className={styles.line}>
          <div className={styles.lineTitle}>Explore</div>
          <ul>
            <li>Platform</li>
            <li>Integrations</li>
            <li>Company</li>
            <li>Support</li>
          </ul>
        </div>
        <div className={styles.line}>
          <div className={styles.lineTitle}>Policies</div>
          <ul>
            <li>Cookies</li>
            <li>Accessibility</li>
            <li>Privacy</li>
          </ul>
        </div>
        <div className={styles.line}>
          <div className={styles.lineTitle}>Contact Us</div>
          <p>+1 416 977 3042</p>
          <div>Contact Us</div>
        </div> */}
      </div>
    </footer>
  )
}
