import styles from './navbar.module.scss'
import Image from 'next/image'
import Link from 'next/link'
import Head from 'next/head'
import Router from 'next/router'
// import dynamic from 'next/dynamic'
import {
  Input,
  Drawer,
  Modal,
  message,
  Dropdown,
  Form,
  Progress,
  Button,
  notification
} from 'antd'
import { useState, useEffect } from 'react'
import {
  MenuOutlined,
  SearchOutlined,
  SolutionOutlined,
  ImportOutlined
} from '@ant-design/icons'
import {
  signInWithPopup,
  signInWithRedirect,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  FacebookAuthProvider,
  TwitterAuthProvider,
  sendPasswordResetEmail,
  getRedirectResult,
  signOut
} from 'firebase/auth'
// import { getApps } from 'firebase/app'
import zxcvbn from 'zxcvbn'
import {
  request,
  encrypt,
  decrypt,
  isIncognitoMode,
  _firebaseAuth
} from '@utils'
import {
  selectAuthState,
  setAuthState,
  setUserInfo,
  setSignShow
} from '@store/authSlice'
import useLocation from '@/utils/hooks/useLocation'
import { useDispatch, useSelector } from 'react-redux'
import useMobileDetect from '@/utils/isMobile'
import cls from 'classnames'
// import { unstable_batchedUpdates } from 'react-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Loader } from '@googlemaps/js-api-loader'

const isMobile = useMobileDetect().isMobile()
const judgeIsMedia = (user) => {
  if (user) {
    if (user.photoURL && user.photoURL.indexOf('facebook')) {
      return true
    } else {
      return user.emailVerified
    }
  }
}

export default function Layout(props) {
  // const [select2open, setSelect2open] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [searchOpen, setSearchOpen] = useState(false)
  const [form, setForm] = useState({
    location: null,
    name: null
  })
  const [locationSlide, setLocationSlide] = useState(false)
  const [history, setHistory] = useState([])
  // const [signVisible, setSignVisible] = useState(false)
  const [isSignUp, setIsSignUp] = useState(false)
  // const [email, setEmail] = useState('')
  // const [password, setPassword] = useState('')
  // const [confirmPassword, setConfirmPassword] = useState('')
  // const [user, setUser] = useState(null)
  const [now, setNow] = useState(Date.now())
  // const [signForm] = Form.useForm()
  const [passwordStrength, setPasswordStrength] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isForgotPw, setIsForgotPw] = useState(false)
  // 重置密码验证码相关
  // const [verificationCode, setVerificationCode] = useState('')
  const [timeLeft, setTimeLeft] = useState(60) // 倒计时剩余时间
  const [isSending, setIsSending] = useState(false) // 是否正在发送验证码
  const [auth, setAuth] = useState(null)
  const [hideFb, setHideFb] = useState(false)
  const [precisionLat, setPrecisionLat] = useState(null)
  const [precisionLng, setPrecisionLng] = useState(null)
  const [searchList, setSearchList] = useState([])
  const [googleService, setGoogleService] = useState(null)
  const [timer, setTimer] = useState(null)

  const loc = useLocation(true)

  let signForm = {}

  if (Form && Form.useForm) {
    signForm = Form.useForm()[0]
  }

  const [user, firebaseLoading] = useAuthState(_firebaseAuth)

  // const [offsetWidth, setOffsetWidth] = useState(1000)

  const { pathname, query } = Router.useRouter()

  const authState = useSelector(selectAuthState)
  const userInfo = useSelector((state) => state.auth.userInfo)
  const signVisible = useSelector((state) => state.auth.signShow)
  const dispatch = useDispatch()

  useEffect(() => {
    let time = 0
    let timer = setInterval(() => {
      if (
        window &&
        window.google &&
        window.google.maps &&
        window.google.maps.places &&
        window.google.maps.places.AutocompleteService
      ) {
        time = 0
        clearInterval(timer)
        const service = new google.maps.places.AutocompleteService()
        setGoogleService(service)
      } else if (time === 10) {
        clearInterval(timer)
      } else {
        time += 1
      }
    }, 2000)
  }, [])

  useEffect(() => {
    if (!firebaseLoading && user && judgeIsMedia(user) && user.accessToken) {
      request(`/api/user/fire-login`, {
        method: 'POST',
        data: { verifyTokenId: user.accessToken }
      }).then((r) => {
        if (r && r.code === 0) {
          localStorage.setItem('TOKEN', r.data.token)
          setNow(Date.now())
          dispatch(setSignShow(false))
        }
      })
    }
    // if (_firebaseAuth) {
    //   setTimeout(() => {
    //     onAuthStateChanged(_firebaseAuth, (user) => {
    //       console.log('user', _firebaseAuth, user)
    //       if (user && user.emailVerified && user.accessToken) {
    //         request(`/api/user/fire-login`, {
    //           method: 'POST',
    //           data: { verifyTokenId: user.accessToken }
    //         }).then((r) => {
    //           if (r && r.code === 0) {
    //             localStorage.setItem('TOKEN', r.data.token)
    //             setNow(Date.now())
    //           }
    //         })
    //       }
    //     })
    //   }, 500)
    // }
    // let unsubscribe = null
    // // getRedirectResult
    // initializeFirebase().then(async (r) => {
    //   if (r) {
    //     const _auth = initializeAuth(r)
    //     await new Promise((res) => {
    //       setTimeout(() => {
    //         console.log('test start =>', _auth)
    //         _auth.onAuthStateChanged((user) =>
    //           console.log(
    //             'user2',
    //             user,
    //             (err) => console.log('err', err),
    //             (res) => console.log('res', res)
    //           )
    //         )
    //         unsubscribe = onAuthStateChanged(
    //           _auth,
    //           (user) => {
    //             console.log('user', user)
    //             if (user && user.emailVerified && user.accessToken) {
    //               request(`/api/user/fire-login`, {
    //                 method: 'POST',
    //                 data: { verifyTokenId: user.accessToken }
    //               }).then((r) => {
    //                 if (r && r.code === 0) {
    //                   localStorage.setItem('TOKEN', r.data.token)
    //                   setNow(Date.now())
    //                 }
    //               })
    //             }
    //             res(true)
    //           },
    //           (err) =>
    //             console.log(
    //               'err => ',
    //               Modal.error({
    //                 title: err.code,
    //                 content: err.message
    //               })
    //             )
    //         )
    //       }, 1000)
    //     })
    //     setAuth(_auth)
    //   }
    // })
    // return () => {
    //   if (unsubscribe) {
    //     return unsubscribe()
    //   }
    // }
    // const firebaseConfig = {
    //   apiKey: 'AIzaSyCJgYi9mEzsOJpSxNwMlaOLQf3f4--YhU0',
    //   authDomain: 'y-montessori.firebaseapp.com',
    //   projectId: 'y-montessori',
    //   storageBucket: 'y-montessori.appspot.com',
    //   messagingSenderId: '116077103998',
    //   appId: '1:116077103998:web:15f83b884c581d5cbfc12f',
    //   measurementId: 'G-K6RWT5R3YM'
    // }
    // // Initialize Firebase
    // const app = initializeApp(firebaseConfig)
    // const _auth = getAuth()
    // setAuth(_auth)
    // const unsubscribe = onAuthStateChanged(
    //   _auth,
    //   (user) => {
    //     unstable_batchedUpdates(() => {
    //       console.log('test: user =>', user)
    //     })
    //     if (user && user.emailVerified && user.accessToken) {
    //       request(`/api/user/fire-login`, {
    //         method: 'POST',
    //         data: { verifyTokenId: user.accessToken }
    //       }).then((r) => {
    //         if (r && r.code === 0) {
    //           localStorage.setItem('TOKEN', r.data.token)
    //           setNow(Date.now())
    //         }
    //       })
    //     }
    //   },
    //   (err) => {
    //     Modal.error({
    //       title: err.code,
    //       content: err.message
    //     })
    //   }
    // )
    // await getRedirectResult(_auth)
    //   .then((result) => {
    //     if (result && result.user && result.user.accessToken) {
    //       request(`/api/user/fire-login`, {
    //         method: 'POST',
    //         data: { verifyTokenId: result.user.accessToken }
    //       }).then((r) => {
    //         if (r && r.code === 0) {
    //           localStorage.setItem('TOKEN', r.data.token)
    //           setNow(Date.now())
    //         }
    //       })
    //     }
    //   })
    //   .catch((err) => console.log(err))
    // return () => {
    //   if (unsubscribe) {
    //     return unsubscribe()
    //   }
    // }
  }, [user, firebaseLoading])

  useEffect(() => {
    let intervalId
    if (timeLeft > 0 && isSending) {
      intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1)
      }, 1000)
    } else {
      setIsSending(false)
    }
    return () => clearInterval(intervalId)
  }, [timeLeft, isSending])

  useEffect(() => {
    try {
      const his = JSON.parse(localStorage.getItem('location-history'))
      if (Array.isArray(his)) {
        setHistory(his)
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    // 获取用户信息
    const token = localStorage.getItem('TOKEN')
    if (token) {
      request(`/api/user/info`, {
        headers: { token },
        untips: true
      }).then((r) => {
        if (r.code === 0) {
          dispatch(setUserInfo(r.data))
          dispatch(setAuthState(true))
        }
      })
    }
    isIncognitoMode().then((isIncognito) => {
      if (isIncognito) {
        setHideFb(true)
      } else {
        setHideFb(false)
      }
    })
  }, [now])

  // 如果存在验证参数，则验证并自动登录
  useEffect(() => {
    ;(async () => {
      const { verification, p, email } = query
      if (verification && p && email && _firebaseAuth) {
        // http://localhost:3000/?verification=true&p=U2FsdGVkX18nzEH1IpcW8P29rpsl92VPUklmToaQd/M=&email=lindamissjk@gmail.com
        try {
          const userCredential = await signInWithEmailAndPassword(
            _firebaseAuth,
            email,
            decrypt(decodeURIComponent(p), 'y')
          )
          const user = userCredential.user
          const r = await request(`/api/user/fire-login`, {
            method: 'POST',
            data: { verifyTokenId: user.accessToken }
          })
          if (r.code === 0) {
            Modal.success({
              title: 'Welcome to Ymontessori. Your account has been activated',
              onOk() {
                setNow(Date.now())
                Router.replace(pathname)
              }
            })
            localStorage.setItem('TOKEN', r.data.token)
          }
        } catch (err) {
          console.log(err)
          // Modal.error({
          //   title: err.code,
          //   content: err.message
          // })
        }
      }
    })()
  }, [query])

  useEffect(() => {
    setPasswordStrength(null)
    // signForm.resetFields()
  }, [isSignUp])

  const setMaxWidth = () => {
    switch (pathname) {
      case '/':
      case '/schools/guide':
        return '100%'
      case '/list':
        return 'calc(100% - 40px)'
      default:
        return 'calc(100% - 40px)'
    }
  }

  const onSearch = () => {
    const query = {}
    for (const key in form) {
      if (Object.hasOwnProperty.call(form, key)) {
        if (form[key]) {
          query[key] = form[key]
        }
        if (
          key === 'location' &&
          form.location === 'Current Location' &&
          precisionLat &&
          precisionLng
        ) {
          query.ll = `${precisionLng},${precisionLat}`
        }
      }
    }
    setSearchOpen(false)
    Router.push({ pathname: '/schools', query })
  }

  useEffect(() => {
    if (pathname === '/schools') {
      setForm(query)
    }
  }, [pathname, query])

  const selectCurrentHistory = (text) => {
    setForm({ ...form, location: text })
  }

  const selectCurrentLocation = async (e) => {
    // if (navigator.geolocation) {
    //   // 支持geolocation
    //   function getLocation() {
    //     return new Promise((res) => {
    //       // const desiredAccuracy = 20 // 期望的位置精确度，单位为米
    //       navigator.geolocation.getCurrentPosition(
    //         async (position) => {
    //           // const accuracy = position.coords.accuracy // 获取当前位置的精确度
    //           // 当前位置精确度满足要求，可以使用该位置信息
    //           res(position.coords)
    //         },
    //         (error) => {
    //           res(error)
    //         },
    //         { enableHighAccuracy: true }
    //       )
    //     })
    //   }
    //   const r = await getLocation()
    //   if (r.latitude && r.longitude) {
    //     setPrecisionLat(r.latitude)
    //     setPrecisionLng(r.longitude)
    //   }
    // }
    if (loc && loc[0] && loc[0].lat) {
      setPrecisionLat(loc[0].lat)
      setPrecisionLng(loc[0].lng)
      setForm({ ...form, location: 'Current Location' })
    } else {
      notification.error({ message: 'Unable to get your exact location' })
    }
  }

  const onBlurLocation = () => {
    setTimeout(() => {
      setLocationSlide(false)
    }, 200)
  }

  const signModalOpt = {
    getContainer: false,
    open: signVisible,
    footer: null,
    onCancel() {
      // setSignVisible(false)
      dispatch(setSignShow(false))
    }
  }

  const signUp = () => {
    // setSignVisible(true)
    dispatch(setSignShow(true))
    setIsSignUp(true)
  }

  const login = () => {
    dispatch(setSignShow(true))
    setIsSignUp(false)
  }

  const handleReset = () => {
    setPasswordStrength(null)
    signForm.resetFields()
  }

  const validateConfirmPassword = (_, value) => {
    if (
      value &&
      value.length > 0 &&
      value !== signForm.getFieldValue('password')
    ) {
      return Promise.reject(
        new Error('The password entered twice does not match!')
      )
    }
    return Promise.resolve()
  }

  const validatePassword = (_, value) => {
    // 此处为简化版密码强度判断，实际应用可使用更复杂的算法
    if (value && value.length > 0 && value.length < 8) {
      setPasswordStrength(null)
      return Promise.reject(
        new Error(
          'Please ensure that your password has a minimum length of 8 digits.'
        )
      )
    } else if (value && value.length >= 8) {
      const pw = zxcvbn(value)
      const score = pw.score * 25
      setPasswordStrength(score === 0 ? 25 : score)
      if (score < 75) {
        return Promise.reject(
          new Error(
            pw.feedback.suggestions ||
              'Your current password is too simple. Please enter a password that includes both letters and numbers.'
          )
        )
      }
    }
    return Promise.resolve()
  }

  // 注册 - 邮箱登录需要验证是否激活
  const register = async (values) => {
    setLoading(true)
    try {
      const userCredential = await createUserWithEmailAndPassword(
        _firebaseAuth,
        values.username,
        values.password
      )
      const r = await encrypt(values.password, 'y')
      const actionCodeSettings = {
        url: `${location.origin}?verification=true&p=${encodeURIComponent(
          r.encryptedData
        )}&email=${values.username}`,
        handleCodeInApp: false
      }
      await sendEmailVerification(userCredential.user, actionCodeSettings)
      // Obtain code from the user.
      setLoading(false)
      // setSignVisible(false)
      dispatch(setSignShow(false))
      handleReset()
      Modal.success({
        title: `We have sent you a verification email. Please open the email and click on the link to
      validate your account.`,
        okCancel: true,
        okText: 'Check it out',
        cancelText: 'Close',
        onOk() {
          const emailUrl = values.username.split('@')[1]
          const specialEmails = ['gmail.com', 'outlook.com']
          const index = specialEmails.indexOf(emailUrl)
          if (index > -1) {
            window.open('https://' + specialEmails[index])
          } else {
            window.open(`https://mail.${emailUrl}`)
          }
        }
      })
    } catch (error) {
      setLoading(false)
      let msg = 'A writing accident occurred during registration.'
      let errMsg = '' + error
      if (errMsg.indexOf('email-already-in-use') > -1) {
        msg =
          'The current email has already been registered, please use a different email.'
      } else {
        msg = error.message
      }
      message.error({ title: error.code, content: msg })
    }
  }

  /**登录模块 */
  const loginInGoogle = () => {
    // const fn = isMobile ? signInWithRedirect : signInWithPopup
    const fn = signInWithPopup
    const provider = new GoogleAuthProvider()
    localStorage.removeItem('TOKEN')
    fn(_firebaseAuth, provider)
    // .then((result) => {
    //   // This gives you a Google Access Token. You can use it to access the Google API.
    //   // const credential = GoogleAuthProvider.credentialFromResult(result);
    //   // const token = credential.accessToken;
    //   // The signed-in user info.
    //   const user = result.user
    //   // const q = location.search.replace('?q=', '')
    //   // console.log(user);
    //   request('/api/user/fire-login', {
    //     method: 'POST',
    //     data: { verifyTokenId: user.accessToken }
    //   }).then((r) => {
    //     // setSignVisible(false)
    //     dispatch(setSignShow(false))
    //     localStorage.setItem('TOKEN', r.data.token)
    //     setNow(Date.now())
    //     // fetchUserInfo()
    //     // const urlParams = new URL(window.location.href).searchParams;
    //     // history.push(q ? decodeURIComponent(q) : '/')
    //   })
    //   // IdP data available using getAdditionalUserInfo(result)
    //   // ...
    // })
    // .catch((err) => {
    //   Modal.error({
    //     title: err.code,
    //     content: err.message
    //   })
    // })
  }

  const loginInFacebook = async () => {
    const provider = new FacebookAuthProvider()
    // provider.addScope('email')
    localStorage.removeItem('TOKEN')
    await signInWithPopup(_firebaseAuth, provider)
    // .then((result) => {
    //   // This gives you a Google Access Token. You can use it to access the Google API.
    //   // const credential = GoogleAuthProvider.credentialFromResult(result);
    //   // const token = credential.accessToken;
    //   // The signed-in user info.
    //   const user = result.user
    //   // const q = location.search.replace('?q=', '')
    //   // console.log(user);
    //   request('/api/user/fire-login', {
    //     method: 'POST',
    //     data: { verifyTokenId: user.accessToken }
    //   }).then((r) => {
    //     // setSignVisible(false)
    //     dispatch(setSignShow(false))
    //     localStorage.setItem('TOKEN', r.data.token)
    //     setNow(Date.now())
    //     // fetchUserInfo()
    //     // const urlParams = new URL(window.location.href).searchParams;
    //     // history.push(q ? decodeURIComponent(q) : '/')
    //   })
    //   // IdP data available using getAdditionalUserInfo(result)
    //   // ...
    // })
    // .catch((err) => {
    //   const errorCode = err.code
    //   const errorMessage = err.message
    //   // The email of the user's account used.
    //   const email = err.customData.email
    //   // AuthCredential type that was used.
    //   const credential = FacebookAuthProvider.credentialFromError(err)
    //   console.log(credential)
    //   message.error({ content: err.message })
    // })
  }

  const loginInTwitter = async () => {
    const fn = isMobile ? signInWithRedirect : signInWithPopup
    const provider = new TwitterAuthProvider()
    fn(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user
        // const q = location.search.replace('?q=', '')
        // console.log(user);
        request('/api/user/fire-login', {
          method: 'POST',
          data: { verifyTokenId: user.accessToken }
        }).then((r) => {
          // setSignVisible(false)
          dispatch(setSignShow(false))
          localStorage.setItem('TOKEN', r.data.token)
          setNow(Date.now())
          // fetchUserInfo()
          // const urlParams = new URL(window.location.href).searchParams;
          // history.push(q ? decodeURIComponent(q) : '/')
        })
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((err) => {
        message.error({ content: err.message })
      })
  }

  const loginUser = async (values) => {
    try {
      setLoading(true)
      // 登录，这里要用firebase
      signInWithEmailAndPassword(
        _firebaseAuth,
        values.username,
        values.password
      )
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user
          console.log(user.emailVerified)
          if (!user.emailVerified) {
            let modal = null
            setLoading(false)
            const retryVerificationEmail = async (_modal) => {
              const r = await encrypt(values.password, 'y')
              const actionCodeSettings = {
                url: `${location.origin}?verification=true&p=${r.encryptedData}&email=${values.username}`,
                handleCodeInApp: false
              }
              await sendEmailVerification(user, actionCodeSettings)
              if (modal) {
                modal.update({
                  content: (
                    <div>
                      <p style={{ margin: 0 }}>
                        Your email address has not been verified yet. Please
                        click on the confirmation link in the email and try
                        logging in again.
                      </p>
                      <p>The activation link has been resent to your email.</p>
                    </div>
                  )
                })
              }
            }
            logout()
            modal = Modal.error({
              title: 'Please verify your email address',
              content: (
                <div>
                  <p style={{ margin: 0 }}>
                    Your email address has not been verified yet. Please click
                    on the confirmation link in the email and try logging in
                    again.
                  </p>
                  <p>
                    I have not received any email.{' '}
                    <a onClick={retryVerificationEmail}>Resending Now</a>
                  </p>
                </div>
              )
            })
          } else {
            request(`/api/user/fire-login`, {
              method: 'POST',
              data: { verifyTokenId: user.accessToken }
            })
              .then((r) => {
                setLoading(false)
                if (r.code !== 0) {
                  message.error({ content: 'login fail, please try again！' })
                } else {
                  localStorage.setItem('TOKEN', r.data.token)
                  setNow(Date.now())
                  // setSignVisible(false)
                  dispatch(setSignShow(false))
                }
              })
              .catch(() => {
                setLoading(false)
              })
          }
        })
        .catch((error) => {
          console.log(error)
          setLoading(false)
          message.error({ content: 'login fail, please try again！' })
        })
    } catch (error) {
      console.log(error)
      setLoading(false)
      message.error({ content: 'login fail, please try again！' })
    }
  }

  const resetUser = async (values) => {
    sendPasswordResetEmail(_firebaseAuth, signForm.getFieldValue('username'))
      .then(function () {
        // 邮件发送成功
        notification.success({
          message: 'Successfully',
          description:
            'Your password reset request has been sent to your email. Please go to your email and click on the corresponding link to reset your password.'
        })
        setIsForgotPw(false)
      })
      .catch(function (error) {
        setIsSending(false)
        // 发送邮件时出现错误
      })
    // confirmPasswordReset(auth, values.code, values.newPassword)
    //   .then(function () {
    //     // 密码重置完成
    //     notification.success({
    //       message: 'Successfully',
    //       description: 'Your password has been successfully reset.'
    //     })
    //     setIsForgotPw(false)
    //   })
    //   .catch(function (error) {
    //     // 密码重置时遇到错误
    //   })
  }

  const hasUser = authState

  const logout = async () => {
    await signOut(_firebaseAuth)
    localStorage.removeItem('TOKEN')
    dispatch(setAuthState(false))
    dispatch(setUserInfo(null))
  }

  const userMenus = [
    {
      key: '1',
      label: (
        <div className={styles.userMenuItem}>
          <Link href='/aboutme' style={{ color: '#333' }}>
            <SolutionOutlined /> User Center
          </Link>
        </div>
      )
    },
    {
      type: 'divider'
    },
    {
      key: '5',
      label: (
        <div className={styles.userMenuItem} onClick={logout}>
          <ImportOutlined /> Log Out
        </div>
      )
    }
  ]

  const getCurrentLocation = () => {
    return new Promise((res, rej) => {
      if (!precisionLat || !precisionLng) {
        if (navigator.geolocation) {
          // 支持geolocation
          navigator.geolocation.getCurrentPosition(function (position) {
            // Get the coordinates of the current position.
            var lat = position.coords.latitude
            var lng = position.coords.longitude
            setPrecisionLat(lat)
            setPrecisionLng(lng)
            res({ lat, lng })
          })
        } else {
          rej(false)
        }
      } else {
        res({ lat: precisionLat, lng: precisionLng })
      }
    })
  }

  const onInputAddress = async (e) => {
    const address = e.target.value
    setForm({ ...form, location: address })
    const { lat, lng } = await getCurrentLocation()
    if (googleService) {
      clearTimeout(timer)
      let t = setTimeout(() => {
        if (address) {
          googleService.getQueryPredictions(
            {
              input: address,
              radius: 100,
              location: new google.maps.LatLng(lat, lng)
            },
            function (results) {
              setSearchList(results.map((_) => _.description))
            }
          )
        }
      }, 1000)
      setTimer(t)
    }
  }

  const mainPaths = ['/', '/schools/guide']
  console.log('pathname', mainPaths.indexOf(pathname))

  return (
    <div
      className={mainPaths.indexOf(pathname) > -1 ? styles.nav2 : styles.nav}
    >
      <Head>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
      </Head>
      <div className={styles.navBox} style={{ maxWidth: setMaxWidth() }}>
        {/* 移动端导航窗口 */}
        <Drawer
          className={styles.drawer}
          placement='left'
          width='100%'
          closable={false}
          onClose={() => setMenuOpen(false)}
          open={menuOpen}
        >
          <div className={styles.menu}>
            <ul onClick={() => setMenuOpen(false)}>
              <li className={pathname === '/' ? styles.active : ''}>
                <Link href='/'>Home</Link>
              </li>
              <li
                className={pathname === '/schools/guide' ? styles.active : ''}
              >
                <Link href='/schools/guide'>Find A School</Link>
              </li>
              <li className={pathname === '/resources' ? styles.active : ''}>
                <Link href='/resources'>Knowledge Center</Link>
              </li>
              <li className={pathname === '/contact' ? styles.active : ''}>
                <Link href='/contact'>About Us</Link>
              </li>
            </ul>
          </div>
        </Drawer>
        {/* 移动端搜索 */}
        <Drawer
          className={styles.drawer2}
          placement='top'
          width='100%'
          closable={false}
          onClose={() => setSearchOpen(false)}
          open={searchOpen}
        >
          <div className={styles.filter}>
            <div className={styles.filterItem}>
              <div className={styles.filterItemBottom}>
                <div className={styles.filterItemBottomInput}>
                  <Input
                    placeholder='School Name'
                    className={styles.input}
                    value={form.name}
                    onChange={(e) => setForm({ ...form, name: e.target.value })}
                    onPressEnter={(e) => onSearch()}
                    autoFocus
                  />
                </div>
              </div>
            </div>
            <div className={styles.filterItem}>
              <div className={styles.filterItemBottom}>
                <div className={styles.filterItemBottomInput}>
                  <Input
                    placeholder='City, Zip Code, Address'
                    onBlur={onBlurLocation}
                    onFocus={() => setLocationSlide(true)}
                    className={styles.input}
                    value={form.location}
                    onChange={(e) =>
                      setForm({ ...form, location: e.target.value })
                    }
                    onPressEnter={(e) => {
                      onSearch()
                    }}
                  />
                  <div
                    className={styles.filterItemBottomInputSlide}
                    style={{ height: locationSlide ? 'auto' : 0 }}
                  >
                    <div
                      className={styles.filterItemBottomInputSlideCurrent}
                      onClick={selectCurrentLocation}
                    >
                      <img src='/position-current.png' alt='Current Location' />{' '}
                      Current Location
                    </div>
                    <div className={styles.filterItemBottomInputSlideHistory}>
                      {history.map((_) => (
                        <div
                          className={
                            styles.filterItemBottomInputSlideHistoryItem
                          }
                          key={_}
                          onClick={selectCurrentHistory.bind(null, _)}
                        >
                          <img src='/history.png' alt='history' /> {_}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.filterItem}>
              <div className={styles.filterItemBtn} onClick={onSearch}>
                Search
              </div>
            </div>
          </div>
        </Drawer>
        <div className={styles.littleMenu} onClick={() => setMenuOpen(true)}>
          <MenuOutlined style={{ color: 'white', fontSize: 24 }} />
        </div>
        <div className={styles.logo}>
          <a
            title='Y Montessori'
            onClick={() => Router.push({ pathname: '/' })}
          >
            <img
              src={'/logo_small.png'}
              width={180}
              height={180}
              alt='Y Montessori'
            />
          </a>
        </div>
        <div className={styles.menu}>
          <ul>
            <li className={pathname === '/' ? styles.active : ''}>
              <Link href='/'>Home</Link>
            </li>
            <li className={pathname === '/schools/guide' ? styles.active : ''}>
              <Link href='/schools/guide'>Find A School</Link>
            </li>
            <li className={pathname === '/resources' ? styles.active : ''}>
              <Link href='/resources'>Knowledge Center</Link>
            </li>
            <li className={pathname === '/contact' ? styles.active : ''}>
              <Link href='/contact'>About Us</Link>
            </li>
          </ul>
        </div>
        {/* 登录窗口 */}
        <Modal {...signModalOpt}>
          {isSignUp ? (
            <div className={styles.signUpBox}>
              <div className={styles.signUpBoxHead}>
                <div className={styles.signUpBoxHeadTitle}>
                  Sign Up for Y Montessori
                </div>
                <div className={styles.signUpBoxHeadDesc}>
                  Connect with great local businesses
                </div>
              </div>
              <div className={styles.signUpBoxFirebase}>
                <div
                  className={styles.signUpBoxFirebaseItem}
                  onClick={loginInFacebook}
                >
                  <button
                    className={styles.signUpBoxFirebaseItemFacebook}
                    type='submit'
                  >
                    <i></i>
                    <b>Continue with Facebook</b>
                  </button>
                </div>
                <div
                  className={styles.signUpBoxFirebaseItem}
                  onClick={loginInGoogle}
                >
                  <button
                    className={styles.signUpBoxFirebaseItemGoogle}
                    type='submit'
                  >
                    <i></i>
                    <b>Continue with Google</b>
                  </button>
                </div>
                {/* <div
                  className={styles.signUpBoxFirebaseItem}
                  onClick={loginInTwitter}
                >
                  <button
                    className={styles.signUpBoxFirebaseItemTwitter}
                    type="submit"
                  >
                    <i></i>
                    <b>Continue with Twitter</b>
                  </button>
                </div> */}
              </div>
              <div className={styles.signUpBoxLine}>OR</div>
              <div className={styles.signUpBoxAccount}>
                <Form
                  form={signForm}
                  onFinish={register}
                  onReset={handleReset}
                  className={styles.signUpBoxAccountForm}
                >
                  <Form.Item
                    name='username'
                    label=''
                    rules={[
                      {
                        required: true,
                        message: 'please enter your email!'
                      },
                      {
                        required: true,
                        type: 'email',
                        message: 'Please enter the correct email!'
                      }
                    ]}
                  >
                    <Input size='large' placeholder='Email' />
                  </Form.Item>

                  <Form.Item
                    name='password'
                    label=''
                    rules={[
                      {
                        required: true,
                        message: 'please enter your password!'
                      },
                      {
                        validator: validatePassword
                      }
                    ]}
                  >
                    <Input.Password size='large' placeholder='Password' />
                  </Form.Item>

                  {passwordStrength && (
                    <Form.Item
                      label=''
                      style={{ margin: '-15px 0 5px 0', width: '100%' }}
                    >
                      <Progress
                        percent={passwordStrength}
                        steps={4}
                        strokeColor={[
                          '#e74242',
                          '#EFBD47',
                          '#ffa500',
                          '#1bbf1b'
                        ]}
                        showInfo={false}
                      />
                    </Form.Item>
                  )}

                  <Form.Item
                    name='confirmPassword'
                    label=''
                    rules={[
                      {
                        required: true,
                        message: 'Please input confirm the password!'
                      },
                      {
                        validator: validateConfirmPassword
                      }
                    ]}
                  >
                    <Input.Password
                      size='large'
                      placeholder='Confirm Password'
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      style={{
                        width: '100%'
                      }}
                      size='large'
                      type='primary'
                      htmlType='submit'
                      loading={loading}
                    >
                      Sign Up
                    </Button>
                  </Form.Item>
                </Form>
                <p>
                  Already on Y Montessori?{' '}
                  <a onClick={() => setIsSignUp(false)}>Log in</a>
                </p>
              </div>
            </div>
          ) : (
            <div className={styles.loginBox}>
              <div className={styles.loginBoxHead}>
                <div className={styles.loginBoxHeadTitle}>
                  Log in to Y Montessori
                </div>
                <div className={styles.loginBoxHeadDesc}>
                  Connect with great local businesses
                </div>
              </div>
              {!hideFb && (
                <>
                  <div className={styles.loginBoxFirebase}>
                    <div
                      className={styles.loginBoxFirebaseItem}
                      onClick={loginInFacebook}
                    >
                      <button
                        className={styles.loginBoxFirebaseItemFacebook}
                        type='submit'
                      >
                        <i></i>
                        <b>Continue with Facebook</b>
                      </button>
                    </div>
                    <div
                      className={styles.loginBoxFirebaseItem}
                      onClick={loginInGoogle}
                    >
                      <button
                        className={styles.loginBoxFirebaseItemGoogle}
                        type='submit'
                      >
                        <i></i>
                        <b>Continue with Google</b>
                      </button>
                    </div>
                  </div>
                  <div className={styles.loginBoxLine}>OR</div>
                </>
              )}
              {isForgotPw ? (
                <div className={styles.loginBoxAccount}>
                  <Form
                    form={signForm}
                    onFinish={resetUser}
                    onReset={handleReset}
                    className={styles.loginBoxAccountForm}
                  >
                    <Form.Item
                      name='username'
                      style={{ margin: 0 }}
                      label=''
                      rules={[
                        {
                          required: true,
                          message: 'please enter your email!'
                        },
                        {
                          required: true,
                          type: 'email',
                          message: 'Please enter the correct email!'
                        }
                      ]}
                    >
                      <Input size='large' placeholder='Email' />
                    </Form.Item>

                    <Form.Item>
                      <Button
                        style={{
                          width: '100%'
                        }}
                        size='large'
                        type='primary'
                        htmlType='submit'
                        loading={loading}
                      >
                        Reset
                      </Button>
                    </Form.Item>
                  </Form>
                  <p>
                    <a onClick={() => setIsForgotPw(false)}>Back to Login</a>
                  </p>
                </div>
              ) : (
                <div className={styles.loginBoxAccount}>
                  <Form
                    form={signForm}
                    onFinish={loginUser}
                    onReset={handleReset}
                    className={styles.loginBoxAccountForm}
                  >
                    <Form.Item name='username' label=''>
                      <Input size='large' placeholder='Email' />
                    </Form.Item>

                    <Form.Item name='password' label=''>
                      <Input.Password size='large' placeholder='Password' />
                    </Form.Item>
                    <a
                      style={{ textAlign: 'right' }}
                      onClick={() => setIsForgotPw(true)}
                    >
                      Forgot password?
                    </a>

                    <Form.Item>
                      <Button
                        style={{
                          width: '100%'
                        }}
                        size='large'
                        type='primary'
                        htmlType='submit'
                        loading={loading}
                      >
                        Log In
                      </Button>
                    </Form.Item>
                  </Form>
                  <p>
                    New to Y Montessori?{' '}
                    <a onClick={() => setIsSignUp(true)}>Sign Up</a>
                  </p>
                </div>
              )}
            </div>
          )}
        </Modal>
        {mainPaths.indexOf(pathname) > -1 ? (
          <div className={styles.homeSignup}>
            {hasUser ? (
              <div
                className={cls({
                  [styles.signBox]: true,
                  [styles.signUserBox]: hasUser
                })}
              >
                <Dropdown menu={{ items: userMenus }} placement='bottomRight'>
                  <img
                    className={styles.signBoxUserAvatar}
                    src={userInfo.photo_url || '/user.png'}
                    alt={userInfo.name}
                  ></img>
                </Dropdown>
              </div>
            ) : (
              <>
                <div className={styles.signUserBoxPc}>
                  <a onClick={signUp}>Sign Up</a>
                  <a onClick={login}>Log In</a>
                </div>
                <div className={styles.signUserBoxPhone} onClick={login}>
                  <img
                    className={styles.signUserBoxPhoneAvatar}
                    src={'/user.svg'}
                    alt='login in'
                  ></img>
                </div>
              </>
            )}
          </div>
        ) : (
          <div className={styles.onlyPc}>
            <div className={styles.filter}>
              <div className={styles.filterItem}>
                <div className={styles.filterItemBottom}>
                  <div className={styles.filterItemBottomInput}>
                    <Input
                      placeholder='School Name'
                      className={styles.input}
                      value={form.name}
                      onChange={(e) =>
                        setForm({ ...form, name: e.target.value })
                      }
                      onPressEnter={(e) => onSearch()}
                      autoFocus
                    />
                  </div>
                </div>
              </div>
              <div className={styles.filterItem}>
                <div className={styles.filterItemBottom}>
                  <div className={styles.filterItemBottomInput}>
                    <Input
                      placeholder='City, Zip Code, Address'
                      onBlur={onBlurLocation}
                      onFocus={() => setLocationSlide(true)}
                      className={styles.input}
                      value={form.location}
                      onChange={onInputAddress}
                      onPressEnter={(e) => {
                        onSearch()
                      }}
                    />
                    <div
                      className={styles.filterItemBottomInputSlide}
                      style={{ height: locationSlide ? 'auto' : 0 }}
                    >
                      <div
                        className={styles.filterItemBottomInputSlideCurrent}
                        onClick={selectCurrentLocation}
                      >
                        <img
                          src='/position-current.png'
                          alt='Current Location'
                        />{' '}
                        Current Location
                      </div>
                      <div className={styles.filterItemBottomInputSlideHistory}>
                        {searchList.map((_) => (
                          <div
                            className={
                              styles.filterItemBottomInputSlideHistoryItem
                            }
                            key={_}
                            onClick={selectCurrentHistory.bind(null, _)}
                          >
                            <img src='/position2.png' alt='position' /> {_}
                          </div>
                        ))}
                        {history.map((_) => (
                          <div
                            className={
                              styles.filterItemBottomInputSlideHistoryItem
                            }
                            key={_}
                            onClick={selectCurrentHistory.bind(null, _)}
                          >
                            <img src='/history.png' alt='history' /> {_}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.filterItem}>
                <div className={styles.filterItemBtn} onClick={onSearch}>
                  <SearchOutlined
                    style={{ fontSize: 18, fontWeight: 'bold' }}
                  />
                </div>
              </div>
              <div className={styles.filterItem}>
                <div
                  className={styles.filterItemBtn}
                  onClick={() => setSearchOpen(true)}
                >
                  <SearchOutlined
                    style={{ fontSize: 18, fontWeight: 'bold' }}
                  />
                </div>
              </div>
            </div>
            <div
              className={cls({
                [styles.signBox]: true,
                [styles.signUserBox]: hasUser
              })}
              style={{
                minWidth: hasUser ? 60 : 150,
                justifyContent: hasUser ? 'center' : 'space-between'
              }}
            >
              {hasUser ? (
                <div className={styles.signBoxUser}>
                  <Dropdown menu={{ items: userMenus }} placement='bottomRight'>
                    <img
                      className={styles.signBoxUserAvatar}
                      src={userInfo.photo_url || '/user.png'}
                      alt={userInfo.name}
                    ></img>
                  </Dropdown>
                </div>
              ) : (
                <>
                  <div className={styles.signUserBoxPc}>
                    <a onClick={signUp}>Sign Up</a>
                    <a onClick={login}>Log In</a>
                  </div>
                  <div className={styles.signUserBoxPhone} onClick={login}>
                    <img
                      className={styles.signUserBoxPhoneAvatar}
                      src={'/user.svg'}
                      alt='login in'
                    ></img>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
